import styled from 'styled-components';
import { Stack, Button, Typography, ButtonGroup } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Chip from '@mui/material/Chip';


const Container = styled.div`
  ${'' /* background-color: #000000; */}
  display: flex;
  flex-grow: 0;
  justify-content: center;
  margin: 0px 8px;
`;

const DialogBox = styled(Dialog)`
  height: 300px;
`;

const Sc_ButtonGroup = styled(ButtonGroup)`
  display: flex;
`;

const NumberDisplay = styled(Button)`
  ${'' /* box-sizing: border-box; */}
  ${'' /* border-radius: 5px !important; */}
  ${'' /* border: 1px solid #ccc; */}
  ${'' /* color: #ccc; */}
  padding: 0 24px;
  ${props => props.active==='true' && ({
     border: '2px solid #2185D5 !important',
     color: '#2185D5 !important'
  })}
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function NumberDial(props) {

  const [value, setValue] = React.useState(props.initialValue);

  function handleChange(action){

    let selectedAction = action;

    let valueMemory = value;
    if(selectedAction === 'minus'){
        if(valueMemory > 1) {
            valueMemory = valueMemory - 1;
            props.onChange('minus');
        } else if(valueMemory === 1){
            valueMemory = 0;
            props.onChange('minus');
        } else {

        }
        setValue(valueMemory);
    } else if (selectedAction === 'plus') {
        if( Number(props.limit) >= 1) {
            valueMemory = valueMemory + 1;
            setValue(valueMemory);
            props.onChange('plus');
        } else {
            let alertMessage = 'exceed limit';
            props.onAlert(alertMessage);
        } 
    }
  }

  React.useEffect(() => {
    let mounted = true;
    if(mounted) {
      // readDialogs();
    }
    return () => {
        mounted = false;
    }
}, [value]);

  return (
      <Container>
        <Sc_ButtonGroup
          variant="contained"
          // pacing={2} 
          // direction="row"
        >
          <Button disabled={value === 0 ? true : false} variant="contained" onClick={() => handleChange('minus')}>
            <FontAwesomeIcon icon={faMinus} />
          </Button>
          <NumberDisplay active={value > 0 ? 'true' : 'false'} label={value && value} color="white" variant="contained">{value && value}</NumberDisplay>
          <Button disabled={value === 3 ? true : false} variant="contained" onClick={() => handleChange('plus')}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Sc_ButtonGroup>
      </Container>
  );
}