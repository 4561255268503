import React from 'react';
import styled from 'styled-components';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ProductDial from './ProductDial';

const ScContainer = styled(Box)`
    display: flex;
    flex-direction: row;
`;

const ScBox = styled(Box)`
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
`;

const ScMiniBox = styled(Box)`
    display: flex;
`;

const ImageBox = styled(Box)`
    width: 100%;
`;


const ScDialBox = styled(Box)`
    align-items: center;
    display: flex;
    flex-grow: 0;
    justify-content: flex-end;
`;


function CartItem(props) {

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.up('xs'));
    const sm = useMediaQuery(theme.breakpoints.up('sm'));

    function renderSugar(){
        let sugarRecord = props.rootContent.filter((el) => {
            return el.fields['name'] === `${props.item.sugar}-sugar`;
        })
        return sugarRecord[0] && sugarRecord[0].fields[`${props.language}`];

    }

    function renderIce(){
        let iceRecord = props.rootContent.filter((el) => {
            return el.fields['name'] === `${props.item.temperature}`;
        })
        return iceRecord[0] && iceRecord[0].fields[`${props.language}`];
    }

    function handleChangeQuantity(action, inputItem) {
        props.passHandleChangeQuantity(action, inputItem);
    }
    
    return (
        <ScContainer style={{ position: sm ? 'unset' : xs ? 'relative' : 'unset'}}>
            <ScBox style={{ flexDirection: sm ? 'row' : 'column' }}>
                <ScMiniBox>
                    <ImageBox>
                        {   props.item && props.item.product && props.item.product.fields.images &&
                            <CardMedia
                            component="img"
                            sx={{ width: sm ? 140 : '100%', height:140, objectFit: 'cover' }}
                            image={`${props.item.product.fields.images[0].thumbnails.large.url}`}
                            alt="item image"
                            />
                        }
                    </ImageBox>
                </ScMiniBox>
                <ScMiniBox>
                    <CardContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography gutterBottom variant="h5" component="div">
                            {   props.item && props.item.product && 
                                props.item.product.fields[`${props.language}`]
                            }
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {   
                                props.item && props.rootContent && props.language && props.item.size &&
                                props.item.size.fields[`${props.language}`]
                            }
                        </Typography>
                        {   props.item && props.rootContent && props.language && props.item['sugar'] !== 'NaN' && props.item['sugar-name'] !== 'NaN' && props.item['temperature'] !== 'NaN' && props.item['temperature-name'] !== 'NaN' ?
                            <Typography variant="body2" color="text.secondary">
                                {renderSugar()} / {renderIce()}
                            </Typography>
                            :
                            props.item && props.rootContent && props.language && props.item['sugar'] !== 'NaN' && props.item['sugar-name'] !== 'NaN' ?
                            <Typography variant="body2" color="text.secondary">
                                {renderSugar()}
                            </Typography>
                            :
                            props.item && props.rootContent && props.language && props.item['temperature'] !== 'NaN' && props.item['temperature-name'] !== 'NaN' &&
                            <Typography variant="body2" color="text.secondary">
                                {renderIce()}
                            </Typography>

                        }
                        <Typography variant="body2" color="text.secondary">
                            {   props.item && props.language && props.item.toppings && props.item.toppings!==[] &&
                                props.item.toppings?.map((el) => {
                                    return el.fields[`${props.language}`] + ' / '
                                })
                            }
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {   props.item && props.language && props.item.customization && props.item.customization!=={} &&
                                props.item.customization[`${props.language}`]
                            }
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {   props.item && props.language && props.item['total-price'] && props.item['discount-type'] === '' ?
                                props.item['total-price'] + ' €'
                                :
                                props.item && props.language && props.item['total-price'] &&
                                <>
                                    <s>
                                        {props.item['total-price']} €
                                    </s>
                                    &nbsp;
                                    {props.item['discounted-total-price']} €
                                </>
                            }

                        </Typography>
                    </CardContent>
                </ScMiniBox>
            </ScBox>
            {   props.item &&
                <ScDialBox style={{ position: sm ? 'unset' : xs ? 'absolute' : 'unset', right: sm ? 'unset' : xs ? '4px' : 'unset', top: sm ? 'unset' : xs ? '4px' : 'unset' }}>
                    <ProductDial item={props.item} onChange={(action, inputItem) => handleChangeQuantity(action, inputItem)} />
                </ScDialBox>
            }
        </ScContainer>
    )
}

export default CartItem;