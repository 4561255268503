import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Stack, Box, CardActionArea, Typography, Divider } from '@mui/material';
import styled from 'styled-components';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Div = styled(Box)`
    display: flex;
`;

const Sc_Card = styled(Card)`
    border-radius: 100%
    width: 32px
`;

const ButtonsBox = styled(ButtonGroup)`
    display: flex;
    min-width: 100% !important;
`;

const SingleButtonBox = styled(Stack)`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

const ScGrid = styled(Grid)`
    min-width: 100%;
    width: 100%;
`;


const IconBox = styled(Box)`
    align-items: center;
    border-radius: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    line-height: unset !important;
    ${'' /* padding: 8px; */}
    min-width: 40px;
    min-height: 40px;
`;

const Sc_CardActionArea = styled(CardActionArea)`
    align-items: center;
    display: flex !important;
    justify-content: center;
`;



export default function IconButtonsGroup ({ content, language, product, helperText, buttons, onClick }){

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.up("xs"));
    const sm = useMediaQuery(theme.breakpoints.up("sm"));
    const md = useMediaQuery(theme.breakpoints.up("md"));
    const lg = useMediaQuery(theme.breakpoints.up("lg"));
    const xl = useMediaQuery(theme.breakpoints.up("xl"));

    const [helperInfo, setHelperInfo] = React.useState(helperText);

    const [currValue, setCurrValue] = React.useState();

    const updateValue = async (input) => {

        const {name, value} = input;
        setCurrValue(value);
        setHelperInfo('');
        value && onClick(value);
    };

    return(

        <Box
            sx={{
                display: 'flex',
                minWidth: '100%',
                '& > *': {
                m: 1,
                },
            }}
        >
            <ButtonsBox
                variant="outlined" 
                aria-label="outlined button group"
            >
                <ScGrid 
                    container 
                    spacing={2}
                >
                    {   
                        buttons && buttons?.map((el, index) => {
                            return (
                                <Grid
                                    key={index}
                                    xs={xl ? 1 : md ? 1 : sm ? 2 : xs ? 4 : 12}
                                >
                                    <SingleButtonBox
                                        key={index}
                                        spacing={1}
                                    >
                                        <IconBox sx={{ backgroundColor: `${el.value === currValue ? '#2185D5 !important' : '#FAFAFA'}`, boxShadow: `${el.value === currValue ? 'unset' : '0px 4px 4px rgba(0, 0, 0, 0.25)'}`}}>
                                            <Sc_CardActionArea onClick={() => updateValue({name: el.name, value: el.value})}>
                                                <CardMedia
                                                    component="img"
                                                    sx={{ width: 24, height: 24, objectFit: 'cover', borderRadius: '10px' }}
                                                    image={el['icon_reverse'] && el.value === currValue ? `${el.icon_reverse}` : el.icon && `${el.icon}`}
                                                    alt='image'
                                                />
                                            </Sc_CardActionArea>
                                        </IconBox>
                                        <Typography variant="body2" sx={{textAlign: 'center'}}>
                                            {el.label}
                                        </Typography>
                                    </SingleButtonBox>
                                </Grid>
                            )
                        })
                    }
                </ScGrid>
            </ButtonsBox>
        </Box>

    )

}