import React, {useState} from 'react';
import styled from 'styled-components';
import useFetch from './useFetch';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { device } from './device';
import { getThemeProps } from '@mui/system';
import CardButtonVertical from './CardButtonVertical';

const ScStack = styled(Stack)`
    min-width: 100%;
`;

const Section = styled(Box)`
    background-color: #E0E0E0;
    border-radius: 10px;
    padding: 8px;
`;

const SC_Card = styled(Card)`

    flex: 1;

    margin: 4px 0;
    max-width: 220px;

    @media ${device.mobileS} {
        max-width: 220px;
    }
    @media ${device.laptopL} {
        max-width: 720px;
    }
`;

let itemSelectedOnlyOne = 0;

let rendered = false;
// let filteredSizeRecords;

function Size({content, preSelect, sizeRecords, store, language, product, onSelectSize}){

    const [filteredSizeRecords, setFilteredSizeRecords] = useState()

    const [cost, setCost] = React.useState(0);
    // const [itemSelected, setItemSelected] = React.useState(product.fields['size-index-name'].length === 1 && itemSelectedOnlyOne);
    const [itemSelected, setItemSelected] = React.useState( preSelect ? 0 : null);

    // const url_storeContent_cups = `/${store}Content_cups`;

    // const { data: storeContent_cups, isPending: storeContent_cups_isPending, error: storeContent_cups_error } = useFetch(url_storeContent_cups);

    const handleClick = React.useCallback((inputSizeRecord, inputId) => {
        if(itemSelected != inputId){
            onSelectSize(inputSizeRecord);
            setItemSelected(inputId);
            // itemSelected = index;
        } else {
            // onSelectCustomization();
            // setItemSelected();
        }
    }); 
    const renderCards = React.useCallback(() => {
        // storeContent_cups.fields[`${input}`]

        return (
            <>
                {   product.fields['cups'] ?
                    <Section
                        sx={ { display: 'flex', flexDirection: 'row' } }
                    >
                        {   filteredSizeRecords?.map((el, index) => {

                            let cupIndex = ~~el - 1;

                            return (
                                <Box key={index} sx={{ flexGrow: 0, flexSchrink: 1, flexBasis: 0, display: 'flex' }} mx={1}>
                                    {   product.fields[`price-${el.fields['cup-syntax-tag']}`] &&
                                        <CardButtonVertical id={index} selected={itemSelected === index ? true : false} record={el} image={el.fields.image[0].thumbnails.large.url} title={el.fields[`${language}`]} text={`${product.fields[`price-${el.fields['cup-syntax-tag']}`]} €`} onClick={(record, key) => {handleClick(record, key)}} />
                                    }
                                </Box>
                            )
                        })}
                    </Section> :
                    <Typography>
                        Loading
                    </Typography>
                }
            </>
        )
    })

    React.useEffect(() => {
        setFilteredSizeRecords(() =>{
            return sizeRecords.filter((el) => {
                return product.fields['cups'].includes(el.id)
            }
        )})
        let mounted = true;
        if(mounted && !rendered) {
            rendered = true;

        }
        return () => {
            mounted = false;
        }
    }, []);


    return (
        <ScStack
            spacing={1}
        >
            <Typography variant="body2_bold">
                {content && content['size'] && content['size'][`${language}`] }
            </Typography>
            { sizeRecords && product.fields['cups'] && filteredSizeRecords && renderCards() }
        </ScStack>
    );
}

export default Size;