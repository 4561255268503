import React, { useEffect, useState } from 'react';
import useFetch from './useFetch';
import styled from 'styled-components';
import logo from '../logo_v1.svg';
import textLogo from '../textLogo_v1.svg';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import { theme } from './theme'
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, useTheme } from '@mui/material/styles';
import { useParams } from 'react-router';
import {useHistory} from 'react-router-dom';

const Image = styled.img`
    ${'' /* width: 48px; */}
    height: 32px;
`;
const FabBox = styled(Box)`
    display: fixed;
`;

export default function Navbar (props){

    const history = useHistory();

    const {activeMapId, language} = useParams();

    const [ activeMap, setActiveMap ] = useState(() => {
        return props.allMaps.filter((el) => {
            return el.id === props.activeMapId;
        })
    });

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    
    const handleCloseUserMenu = () => {

        setAnchorElUser(null);
    };

    const handleSelectMapType = (selectedMapTypeId) => {
        // props.onHandleMapType(selectedMapTypeEn);
        setActiveMap(() => {
            return props.allMaps.filter((el) => {
                return el.id === selectedMapTypeId;
            })
        })
        history.push(`/map/${selectedMapTypeId}/${language}`)
        setAnchorElUser(null);
    };

    useEffect(() => {
        // setActiveMap(() => {
        //     return props.allMaps.filter((el) => {
        //         return el.id === props.activeMapId;
        //     })
        // });
    })

    return (
        <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <ThemeProvider theme={theme}>
                <AppBar position="static" color='neutral'>
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 4 }} >
                                <IconButton
                                    href='/map'
                                >
                                    <Image src={textLogo} />
                                </IconButton>
                            </Box>
                            <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1, mr: 1 }} >
                                <IconButton
                                    href='/map'
                                >
                                    <Image src={  sm ? textLogo : logo } />
                                </IconButton>
                            </Box>
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                {/* {pages.map((page) => (
                                    <Button
                                    key={page}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 1, color: '#000000', display: 'block' }}
                                    >
                                        {page}
                                    </Button>
                                ))} */}
                            </Box>

                            <FabBox sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
                                        <Stack direction="row" spacing={1}>
                                            {   activeMap &&
                                                <Chip 
                                                avatar= {
                                                            <Avatar alt={activeMap[0].fields.en} src={activeMap[0].fields.image[0].thumbnails.small.url} /> 
                                                        } 
                                                label={activeMap[0].fields[`${language}`]} 
                                                />
                                            }
                                            {/* <Chip
                                                avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
                                                label="Avatar"
                                                variant="outlined"
                                            /> */}
                                        </Stack>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {
                                        props.allMaps && props.allMaps?.map((type) => (
                                        <MenuItem key={type.id} onClick={() => handleSelectMapType(type.id)}>
                                            <Typography textAlign="center">{type.fields[`${language}`]}</Typography>
                                        </MenuItem>
                                        ))
                                    }
                                </Menu>
                            </FabBox>
                        </Toolbar>
                    </Container>
                </AppBar>
            </ThemeProvider>
        </Stack>
    );

}