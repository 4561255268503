import React, {useState} from 'react';
import NumberDial from './NumberDial';
import styled from 'styled-components';
import useFetch from './useFetch';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, listItemSecondaryActionClasses, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { device } from './device';
import { getThemeProps } from '@mui/system';
import { PromptProps } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CardButtonWithCounter from './CardButtonWithCounter';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const Container = styled.div`

`;

const DialogBox = styled(Dialog)`
    ${'' /* height: 300px; */}
`;

const Div = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 4px;
    justify-content: stretch;
    justify-items: center; 
    ${'' /* grid-auto-rows: 150px; */}
`;

const NumberDialBox = styled(Box)`

    display: flex;
    text-align: center;
    ${'' /* justify-content: center; */}
    padding: 8px 8px 8px 8px;
    width: 100%;

    @media ${device.tablet} {
    }
`;

const ScCard = styled(Card)`
    display: flex;
    ${'' /* margin: 8px auto; */}
    text-align: center;
    flex-grow: 1;
    max-width: 1440px;

    @media ${device.mobileS} {

    }
    @media ${device.tablet} {

    }
    @media ${device.laptop} {

    }
`;

const ScBox = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 4px auto;
    flex-grow: 1;
    justify-content: center;

    ${'' /* @media ${device.tablet} {

    } */}

`;

const BoxContent = styled(Box)`
    display: flex;
    align-items: flex-start;
    ${'' /* flex-direction: column; */}
    flex-grow: 1;
    height: 100%;

    @media ${device.mobileM} {
        flex-direction: row;
    }
`;

let rendered = false;

const dialogsContent = {};

function ExtraSection({dialogsData, content, store, language, product, allRecords, currRecords, onUpdateExtra}) {

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    const [dialogContent, setDialogContent] = React.useState();
    const [alertMessage, setAlertMessage] = React.useState();
    const [alertTitle, setAlertTitle] = React.useState();
    const [alertAction, setAlertAction] = React.useState();

    const [alertSoldOut, setAlertSoldOut] = React.useState(false);

    const [expand, setExpand] = React.useState(false);
    const [expandIndex, setExpandIndex] = React.useState();
    const [expandCurrValue, setExpandCurrValue] = React.useState(0);

    const [toppings, setToppings] = React.useState([]);

    const [availableToppings, setAvailableToppings] = React.useState(3);

    const [toppingsLimit, setToppingsLimit] = React.useState(3);

    const [itemSelected, setItemSelected] = React.useState();

    const [filteredToppingsRecords, setFilteredToppingsRecords] = useState();

    const [url_rootContent_dialog, setUrl_rootContent_dialog] = React.useState(`/root/dialog`);
    const { data: dialogs, isPending: dialogs_isPending, error: dialogs_error } = useFetch(
        dialogsData ?
        {
            loaded: true,
            data: dialogsData
        }
        :
        url_rootContent_dialog
    );


    function readDialogs ( ) {
        let dialogsMemory = dialogs;

        dialogsMemory && dialogsMemory.forEach(el => {
    
            const dialogItem = {
                'ch': String,
                'en': String,
                'de': String
            }

            dialogItem.ch = el.fields.ch;
            dialogItem.en = el.fields.en;
            dialogItem.de = el.fields.de;

            dialogsContent[`${el.fields.name}`] = dialogItem;
            return dialogsContent;
        })
    }
    async function filterDialog (title, message) {

        let dialogsContentMemory = dialogsContent;
    
        setAlertTitle(dialogsContentMemory[title][`${language}`]);
        setAlertMessage(dialogsContentMemory[message][`${language}`]);
        setAlertAction(dialogsContentMemory['confirm'][`${language}`]);
    }

    const handleClose = () => {
        setAlertSoldOut(false);
    };

    const updateToppings = React.useCallback((action, record) => {

        let actionMemory = action;
        let recordMemory = record;

        let toppingsMemory = toppings;
        let storeContentMemory = allRecords;

        if(actionMemory === 'minus') {
            const removeIndex = toppingsMemory.indexOf(recordMemory);

            toppingsMemory.splice(removeIndex, 1)
            // setToppings(toppingsMemory);
            setAvailableToppings((prevValue) => {
                return prevValue + 1;
            })
            setToppings(toppingsMemory);
            onUpdateExtra(actionMemory, recordMemory);

        } else if (actionMemory === 'plus') {
            setAvailableToppings((prevValue) => {
                return prevValue - 1;
            })
            setToppings((prevValue) => [...prevValue, recordMemory]);
            onUpdateExtra(actionMemory, recordMemory);
        } else{

        }
    });

    const handleAlert = React.useCallback((inputAlert) => {
        let alertMemory = inputAlert;
        let dialogsMemory = dialogs;
        if(alertMemory === 'exceed limit'){
            dialogsContent && filterDialog('toppings-full', 'toppings-full-message');
            setAlertSoldOut(true);
        }
    })

    React.useEffect(() => {
        product && allRecords && setFilteredToppingsRecords(() =>{
            return allRecords.filter((el) => {
                return product.fields['toppings'].includes(el.id)
            }
        )})
        let mounted = true;
        if(mounted && !rendered ) {
            rendered = true;
            readDialogs();
        }
        return () => {
            mounted = false;
        }
    }, []);


    return (
        <Stack
            spacing={2}
        >
            {   content.toppings &&
                <Typography variant="body2_bold">
                    {content['toppings'] && content['toppings'][`${language}`] }
                </Typography>
            }
            {dialogs && readDialogs()}
            <Box sx={{ flexGrow: 1 }}>
                <Grid 
                    container 
                    spacing={2} 
                >
                    {
                        filteredToppingsRecords &&
                        filteredToppingsRecords?.map((el, index) => {
                            return (
                                <Grid key={index} item xs={ xl ? 3 : lg ? 4 : md ? 6 : 12} sx={{ display: 'flex' }}>
                                    <CardButtonWithCounter 
                                        record={el}
                                        disabled={el.fields['sold-out'] ? true : false}
                                        disabledMessage={'sold out'}
                                        image={el.fields.image[0].thumbnails.large.url}
                                        title={el.fields[`${language}`]}
                                        text={`${el.fields.price} €`}
                                        limit={availableToppings}
                                        onAlert={(alertMessage) => handleAlert(alertMessage)}
                                        onChange={(action, record) => updateToppings(action, record)}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Box>
            {   dialogs &&
                <DialogBox
                    open={alertSoldOut}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>
                    { alertTitle && alertTitle }
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {alertMessage && alertMessage}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>
                        {alertAction && alertAction}
                    </Button>
                    </DialogActions>
                </DialogBox>
            }
        </Stack>
    );
}

export default ExtraSection;