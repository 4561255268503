import React, { useCallback, useLayoutEffect, useEffect, useState } from 'react';
import Navigation from './Navigation';
import useFetch from './useFetch';
import {useHistory} from 'react-router-dom';
import { useParams } from 'react-router';
import { styled } from '@mui/material/styles';
import Loading from './Loading';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import readAirtableData from '../hooks/readAirtableData';
import StoreInfoAccordion from './StoreInfoAccordion';
import ProductSectionAccordion from './ProductSectionAccordion';
import Drawer from '@mui/material/Drawer';
import ProductPage from './ProductPage';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import PageNotFound from './PageNotFound';


const today = new Date();
const thisWeekDay = today.getDay();
const currTimeInSeconds = today.getHours()*60*60 + today.getMinutes()*60;

// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
// }));

const StyledStack = styled(Stack)(({theme}) => ({
    backgroundColor: theme.palette.white.main,
    gap: theme.spacing(2),
}));

const ScBox = styled(Box)(({theme}) => ({
    display: 'flex',
    'flexDirection': 'column',
    gap: theme.spacing(2),
    'justifyContent': 'center',
    'margin': '0 auto',
    padding: '16px 16px'
}));

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
            opacity: 1,
            border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
        },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
        color:
            theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
        duration: 500,
        }),
    },
}));

function StoreMain(props) {

    const [rootContent, setRootContent] = useState();
    const [storeContent, setStoreContent] = useState();
    const [storeMedia, setStoreMedia] = useState();
    const [storeProduct, setStoreProduct] = useState();
    const [storeSettings, setStoreSettings] = useState();
    const [storeNotes, setStoreNotes] = useState();
    const [storeProductCategories, setStoreProductCategories] = useState();
    const [storeProductCategoriesObj, setStoreProductCategoriesObj] = useState();

    //start cashier useStates
    const [product_rootContent, setProduct_rootContent] = useState();
    const [product_storeContent, setProduct_storeContent] = useState();

    const [productId, setProductId] = useState();
    const [productItem, setProductItem] = useState();

    const [simpleLayout, setSimpleLayout] = useState(props.simpleLayout);

    //end cashier useStates

    let renderAlert = false;

    const {language, store} = useParams();

    const [url_rootContent_landingPage, setUrl_rootContent_landingPage] = useState('/root/landing-page');
    const [url_storeContent_landingPage, setUrl_storeContent_landingPage] = useState(`/store/landing-page/${store}`);
    const [url_storeContent_media, setUrl_storeContent_media] = useState(`/store/media/${store}`);
    const [url_storeContent_product, setUrl_storeContent_product] = useState(`/store/product/${store}`);
    const [url_storeContent_settings, setUrl_storeContent_settings] = useState(`/store/settings/${store}`);
    const [url_storeContent_productCategories, setUrl_storeContent_productCategories] = useState(`/store/product-categories/${store}`);
    const [url_storeContent_notes, setUrl_storeContent_notes] = useState(`/store/notes/${store}`);

    //start cashier urls
    const [url_rootContent_itemPage, setUrl_rootContent_itemPage] = useState('/root/item-page');
    const [url_storeContent_cups, setUrl_storeContent_cups] = useState(`/store/cups/${store}`);
    const [url_storeContent_customization, setUrl_storeContent_customization] = useState(`/store/customization/${store}`);
    const [url_storeContent_toppings, setUrl_storeContent_toppings] = useState(`/store/toppings/${store}`);
    const [url_rootContent_dialog, setUrl_rootContent_dialog] = useState(`/root/dialog`);
    const [url_storeContent_discounts, setUrl_storeContent_discounts] = useState(`/store/discounts/${store}`);
    //

    const { data: data_rootContent_landingPage, isPending: isPending_rootContent_landingPage, error: error_rootContent_landingPage } = useFetch(url_rootContent_landingPage);
    const { data: data_storeContent_landingPage, isPending: isPending_storeContent_landingPage, error: error_storeContent_landingPage } = useFetch(url_storeContent_landingPage);
    const { data: data_storeContent_media, isPending: isPending_storeContent_media, error: error_storeContent_media } = useFetch(url_storeContent_media);
    const { data: data_storeContent_product, isPending: isPending_storeContent_product, error: error_storeContent_product } = useFetch(url_storeContent_product);
    const { data: data_storeContent_settings, isPending: isPending_storeContent_settings, error: error_storeContent_settings } = useFetch(url_storeContent_settings);
    const { data: data_storeContent_productCategories, isPending: isPending_storeContent_productCategories, error: error_storeContent_productCategories } = useFetch(url_storeContent_productCategories);
    const { data: data_storeContent_notes, isPending: isPending_storeContent_notes, error: error_storeContent_notes } = useFetch(url_storeContent_notes);
    
    //start cashier data
    const { data: data_rootContent_itemPage, isPending: isPending_rootContent_itemPage, error: error_rootContent_itemPage } = useFetch(props.cashier ? url_rootContent_itemPage : 'bypass');
    const { data: data_storeContent_cups, isPending: isPending_storeContent_cups, error: error_storeContent_cups_error } = useFetch(props.cashier ? url_storeContent_cups : 'bypass');
    const { data: data_storeContent_customization, isPending: isPending_storeContent_customization, error: error_storeContent_customization } = useFetch(props.cashier ? url_storeContent_customization : 'bypass');
    const { data: data_storeContent_toppings, isPending: isPending_storeContent_toppings, error: error_storeContent_toppings } = useFetch(props.cashier ? url_storeContent_toppings : 'bypass');
    const { data: data_dialogs, isPending: isPending_dialogs, error: error_dialogs } = useFetch(props.cashier ? url_rootContent_dialog : 'bypass');
    const { data: data_storeContent_discounts, isPending: isPending_storeContent_discounts, error: error_storeContent_discounts } = useFetch(props.cashier ? url_storeContent_discounts : 'bypass');
    //

    //start cashier actions
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const toggleDrawer = (inputBoolean) => (event) => {
        setOpenDrawer(inputBoolean);
    };
    //end cashier actions

    function loadStoreProduct() {
        let storeProductCategoriesMemory = []
        data_storeContent_product && data_storeContent_product?.map(el => {

            if(el.fields['category-index']){
                for(let i = 0; i < el.fields['category-index'].length; i++){
                    if(!storeProductCategoriesMemory.includes(el.fields['category-index'][i])){
                        storeProductCategoriesMemory.push(el.fields['category-index'][i]);
                    }
                }
            }
            
            return storeProductCategoriesMemory;
        });

        data_storeContent_productCategories && storeProductCategoriesMemory.sort(
            function(a,b){

                const filteredCategoriesA = data_storeContent_productCategories.filter((item) => {
                    return item.id === a;
                })
        
                const categoryRecordA = filteredCategoriesA[0];

                const filteredCategoriesB = data_storeContent_productCategories.filter((item) => {
                    return item.id === b;
                })
        
                const categoryRecordB= filteredCategoriesB[0];

                return Number(categoryRecordA.fields.order) - Number(categoryRecordB.fields.order)
            }
        )
        setStoreProductCategories(storeProductCategoriesMemory);
    };

    function loadStoreSettings() {
        data_storeContent_settings && data_storeContent_settings?.map(el => {
            let settingsName = el.fields.name;
            let sectionImages = [];

            return storeSettings[settingsName] = el.fields;

        });
        return storeSettings;
    };

    function checkTime(){
        const storeStartsAt = storeSettings && storeSettings[`open-day-${thisWeekDay}`] && storeSettings[`open-day-${thisWeekDay}`].time;
        const storeClosesAt = storeSettings && storeSettings[`close-day-${thisWeekDay}`] && storeSettings[`close-day-${thisWeekDay}`].time
        if(currTimeInSeconds < storeStartsAt || currTimeInSeconds > storeClosesAt) {
            if( storeSettings && storeSettings['store-open'] && 
                storeSettings['store-open'].checked !== true 
            ){
                renderAlert = true;
            }
        }
    }

    //start cashier action
    function handleItemCall(id){

        setProductId(id);
        let productItemMemory;

        //read product record
        data_storeContent_product?.map(el => {
            
            const currentProduct = el;
            if(currentProduct.id === id){
                // productItem = currentProduct;
                productItemMemory = currentProduct;
                setProductItem(productItemMemory);
                // return productItem;
                return;
            }
        });

        setOpenDrawer(true);
    }
    //end cashier action

    function passCleanUpCart(){
        props.onCleanUpCart();
    }

    function passHandleChangeQuantity(action, inputItem){
        props.onHandleChangeQuantity(action, inputItem);
    }

    function handlePassNote(noteInput){
        props.passNote(noteInput);
    }

    //start cashier action
    function handleClose(){
        setProductId();
        setOpenDrawer(false);
    }

    function passUpdateCart(itemInput){
        props.catchUpdateCart(itemInput);
        setOpenDrawer(false);
    }
    //end cashier action

    function handleLayoutSwitch(event) {
        setSimpleLayout(event.target.checked);
        props.onUpdateLayout(event.target.checked);
    }

    useEffect(() => {

        let rootContentMemory = {};
        let storeContentMemory = {};
        let storeMediaMemory = {};
        let storeProductMemory = {};
        let storeProductCategoriesObjMemory = {}
        let storeSettingsMemory = {}
        let storeNotesMemory = {}

        let rootContent_productMemory ={};

        
        if(data_rootContent_landingPage && !rootContent){
            readAirtableData(data_rootContent_landingPage, rootContentMemory);
            setRootContent(rootContentMemory);
        }

        if(data_rootContent_landingPage){
            readAirtableData(data_storeContent_landingPage, storeContentMemory);
            setStoreContent(storeContentMemory);
        }

        if(data_storeContent_media && !storeMedia){
            readAirtableData(data_storeContent_media, storeMediaMemory);
            setStoreMedia(storeMediaMemory);
        }

        if(data_storeContent_product && !storeProduct){
            readAirtableData(data_storeContent_product, storeProductMemory);
            setStoreProduct(storeProductMemory);
        }

        if(data_storeContent_productCategories && !storeProductCategoriesObj){
            readAirtableData(data_storeContent_productCategories, storeProductCategoriesObjMemory);
            setStoreProductCategoriesObj(storeProductCategoriesObjMemory);
        }

        if(data_storeContent_settings && !storeSettings){
            readAirtableData(data_storeContent_settings, storeSettingsMemory);
            setStoreSettings(storeSettingsMemory);
        }

        if(data_storeContent_notes && !storeNotes){
            readAirtableData(data_storeContent_notes, storeNotesMemory);
            setStoreNotes(storeNotesMemory);
        }

        if(props.cashier && data_rootContent_itemPage && !product_rootContent){
            readAirtableData(data_rootContent_itemPage, rootContent_productMemory);
            setProduct_rootContent(rootContent_productMemory);
        }

        data_storeContent_productCategories && data_storeContent_product && loadStoreProduct();
        data_storeContent_settings && storeSettings && loadStoreSettings();

    }, [

        data_rootContent_landingPage,
        data_storeContent_landingPage,
        data_storeContent_media,
        data_storeContent_product,
        data_storeContent_settings,
        data_storeContent_productCategories,
        data_storeContent_notes,

        data_rootContent_itemPage,
        data_storeContent_cups,
        data_storeContent_customization,
        data_storeContent_toppings,
        data_dialogs,
        data_storeContent_discounts,

    ]);

    // useLayoutEffect(() => {

    //     let rootContent_productMemory ={};

    //     if(props.cashier && data_rootContent_itemPage){
    //         readAirtableData(data_rootContent_itemPage, rootContent_productMemory);
    //         setProduct_rootContent(rootContent_productMemory);
    //     }

    // }, [])


    return (
        <ScBox>
            {
                storeSettings && isPending_storeContent_product && isPending_storeContent_media ? 
                <Loading />
                : 
                <StyledStack>
                    {   rootContent && storeContent && storeSettings && storeNotes && store && language && storeMedia && data_storeContent_notes &&
                        <Navigation 
                            cashier={props.cashier ? true : false}
                            store={store} 
                            language={language} 
                            logo={storeMedia && storeMedia['logo'] ? storeMedia['logo'].attachments[0] : null} 
                            settings={storeSettings} 
                            purchases={props.purchases} 
                            rootContent={rootContent} 
                            storeContent={storeContent} 
                            total={props.total} 
                            notes={data_storeContent_notes}
                            savedNote={props.savedNote && props.savedNote}
                            passNote={(noteRecord) => handlePassNote(noteRecord)}
                            onCleanUpCart={() => passCleanUpCart()} 
                            onHandleChangeQuantity={(action, inputItem) => passHandleChangeQuantity(action, inputItem)} 
                        />
                    }
                    { !props.cashier && data_storeContent_settings && storeSettings && checkTime()}
                    { !props.cashier && data_storeContent_settings && storeSettings && !isPending_storeContent_settings && renderAlert &&
                        <Alert severity="warning">
                            <AlertTitle>{`${storeSettings['closed-store-message-title'][`text-${language}`]}`}</AlertTitle>
                            {storeSettings['closed-store-message-content'][`text-${language}`]}
                        </Alert>
                    }
                    <Box>
                        <FormControlLabel
                            control={
                                <IOSSwitch 
                                    sx={{ m: 1 }} 
                                    checked={props.simpleLayout}
                                    onChange={handleLayoutSwitch}
                                />
                            }
                            label={rootContent && rootContent['simple-menu'] && rootContent['simple-menu'][`${language}`]}
                        />
                    </Box>
                    {/* Store info loading */}
                    { !props.cashier && language && rootContent && storeContent && storeSettings &&
                        <StoreInfoAccordion language={language} settings={storeSettings} rootContent={rootContent} storeContent={storeContent} />
                    }
                    {
                        rootContent && storeContent && data_storeContent_product && data_storeContent_productCategories && storeProductCategories &&
                        storeProductCategories?.map((el, index) => {

                            const filteredCategories = data_storeContent_productCategories.filter((item) => {
                                return item.id === el;
                            })

                            const thisCategoryRecord = filteredCategories[0];
    
                            return(
                                <div key={index}>
                                    {   
                                        isPending_storeContent_product && storeProductCategoriesObj ? 
                                        <div> Loading </div> 
                                        : 
                                        <ProductSectionAccordion 
                                            cashier={props.cashier ? true : false}
                                            simpleLayout={simpleLayout}
                                            key={index} 
                                            index={index} 
                                            language={language} 
                                            store={store} 
                                            rootContent={rootContent} 
                                            storeContent={storeContent} 
                                            categoryRecord={thisCategoryRecord}  
                                            productContent={data_storeContent_product} 
                                            onCashierCallItem={(inputId) => handleItemCall(inputId)}
                                        />
                                    }
                                </div>
                            )
    
                        })
                    }
                </StyledStack>
            }
            {   props.cashier && 
                <React.Fragment>
                    <Drawer
                        anchor="left"
                        open={openDrawer}
                        onClose={toggleDrawer(false)}
                    >
                        <Box 
                            sx={{ width: "80vw" }}
                            role="presentation"
                        >
                            {
                                productId && productItem && product_rootContent && data_storeContent_product && data_storeContent_cups && data_storeContent_customization && data_storeContent_toppings &&
                                <ProductPage 
                                    cashier
                                    store={store} 
                                    language={language} 
                                    product={productId} 
                                    productItem={productItem} 
                                    settings={data_storeContent_settings && storeSettings && storeSettings}
                                    rootContent={product_rootContent} 
                                    products={data_storeContent_product && data_storeContent_product} 
                                    cups={data_storeContent_cups && data_storeContent_cups} 
                                    customization={data_storeContent_customization && data_storeContent_customization} 
                                    toppings={data_storeContent_toppings && data_storeContent_toppings} 
                                    dialogs={data_dialogs && data_dialogs}
                                    discounts={data_storeContent_discounts && data_storeContent_discounts}
                                    onClose={() => handleClose()} 
                                    onUpdateCart={(itemInput) => passUpdateCart(itemInput)}
                                />
                            }
                        </Box>
                    </Drawer>
                </React.Fragment>
            }
        </ScBox>
    );
}

export default StoreMain;