import React,{ useState } from 'react';
import styled from 'styled-components';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea } from '@mui/material';
import { device } from './device';

const SC_Card = styled(Card)`

    flex: 1;

    margin: 4px 0;
    max-width: 220px;
    padding: 8px;

    @media ${device.mobileS} {
        max-width: 220px;
    }
    @media ${device.laptopL} {
        max-width: 720px;
    }
`;

const SC_CardContent = styled(CardContent)`
    padding-bottom: 0px !important;
`;

export default function CardButtonVertical (props){

    const handleClick = React.useCallback((inputRecord, inputId) => {
        props.onClick(inputRecord, inputId);
    }); 

    return(

        <SC_Card style={{ backgroundColor: props.selected ? '#2185D5' : '#ffffff', boxShadow: props.selected ? 'unset' : '0px 4px 4px rgba(0, 0, 0, 0.25' }} sx={{ maxHeight: 220, display: 'flex', flexDirection: 'row', borderRadius: '10px' }}>
            <CardActionArea
                onClick={() => {handleClick(props.record, props.id)}}
            >
                <Box sx={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }} >
                    {/* <Box sx={{ flexGrow: 1, minHeight: 50, maxHeight: 200, maxWidth: 200, width: 50 }} > */}
                        {
                            props.image &&
                            <CardMedia
                                component="img"
                                sx={{ width: 80, height: 80, objectFit: 'cover', borderRadius: '10px' }}
                                image={props.image}
                                alt='image'
                            />
                        }
                    {/* </Box> */}
                    <SC_CardContent sx={{ alignItems: 'center', color: `${props.selected ? '#ffffff' : 'unset' }`, display: 'flex', flexDirection: 'column',padding: '0', pb: 0, minWidth: '100%', my: 1 }}>
                        {   props.title &&
                            <Typography gutterBottom variant="h5" component="div">
                                {props.title}
                            </Typography>
                        }
                        {   props.text &&
                            <Typography 
                                variant="body2" 
                                // color="text.secondary"

                            >
                                {props.text}
                            </Typography>
                        }
                    </SC_CardContent>
                </Box>
            </CardActionArea>
        </SC_Card>

    )

}