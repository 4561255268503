import { responsiveFontSizes, createTheme } from '@mui/material/styles';
import { purple } from '@mui/material/colors';

export const theme = responsiveFontSizes(createTheme({
    palette: {
        primary: {
            main: '#2185D5',
            background: 'rgba(187, 222, 251, 1)',
        },
        secondary: {
            main: '#666666',
        },
        neutral: {
            main: '#f0f0f0',
        },
        danger: {
            main: '#f44336',
        },
        black: {
            main: '#000000',
            o_eightyseven: 'rgba(0, 0, 0, 0.87)',
        },
        white: {
            main: '#ffffff',
        },
        blue_lighten_4: {
            main: 'rgba(187, 222, 251, 1)',
        },
        grey_lighten_2: {
            main: '#E0E0E0',
        },
        grey_lighten_5: {
            main: '#FAFAFA'
        },
        grey: {
            lighten_1: '#BDBDBD',
            lighten_4: '#F5F5F5',
            lighten_5: '#FAFAFA',
            darken_3: '#424242'
        }
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                h1: 'h2',
                h2: 'h2',
                h3: 'h2',
                h4: 'h2',
                h5: 'h2',
                h6: 'h2',
                subtitle1: 'h2',
                subtitle2: 'h2',
                body1: 'span',
                body2: 'span',
                },
            },
        },
    },
    typography: {
        fontFamily: [
            '"Ubuntu"',
            'sans-serif',
        ].join(','),
        h1: {
            fontWeight: 400,
            fontSize: 88,
            lineHeight: 1.09,
            letterSpacing: 1.5
        },
        h2: {
            fontWeight: 400,
            fontSize: 57,
            lineHeight: 1.05,
            letterSpacing: 0.5
        },
        h3: {
            fontWeight: 700,
        },
        h4: {
            fontWeight: 500,
            margin: 0,
        },
        h4_bold: {
            fontSize: 34,
            fontWeight: 700,
            letterSpacing: 0.25,
            lineHeight: '118%',
            margin: 0,
            marginBottom: '0 !important'
        },
        h6: {
            fontWeight: 400,
            fontSize: 20,
            lineHeight: 1.6,
            letterSpacing: 0.25
        },
        h6_bold: {
            fontWeight: 700,
        },
        subtitle1: {
            fontSize: 12,
            color: '#666666'
        },
        body1: {
            fontWeight: 400,
        },
        body1_bold: {
            fontWeight: 700,
        },
        body2: {
            fontSize: 14,
            fontWeight: 400,
        },
        body2_bold: {
            fontSize: 14,
            fontWeight: 700,
        },
        button: {
            fontWeight: 500,
            fontSize: 16,
            lineHeight: '260%',
        },
        button2: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '115%',
        },
        button3: {
            fontWeight: 500,
            fontSize: 12,
            lineHeight: '115%',
        },
        caption: {
            fontSize: 12,
            fontWeight: 400,
        },
    },
    spacing: (factor) => `${0.25 * factor}rem`,
    breakpoints: {
        values: {
            xs: 0,
            sm: 425,
            md: 768,
            lg: 1024,
            xl: 1440,
        },
    },
}));