import React, {useState} from 'react';
import NumberDial from './NumberDial';
import styled from 'styled-components';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';
import { device } from './device';
import Dialog from '@mui/material/Dialog';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Container = styled.div`

`;

const DialogBox = styled(Dialog)`
    ${'' /* height: 300px; */}
`;

const Div = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 4px;
    justify-content: stretch;
    justify-items: center; 
    ${'' /* grid-auto-rows: 150px; */}
`;

const NumberDialBox = styled(Box)`

    display: flex;
    text-align: center;
    ${'' /* justify-content: center; */}
    ${'' /* padding: 8px 8px 8px 8px; */}
    ${'' /* position: absolute; */}
    ${'' /* top: -16px; */}
    ${'' /* right: -32px; */}
    ${'' /* z-index: 999; */}
    ${'' /* min-height: 100%; */}

    @media ${device.tablet} {
    }
`;

const ScCard = styled(Card)`
    border-radius: 10px !important;
    display: flex;
    ${'' /* margin: 8px auto; */}
    text-align: center;
    flex-grow: 1;
    max-width: 1440px;
    overflow: visible !important;

    @media ${device.mobileS} {

    }
    @media ${device.tablet} {

    }
    @media ${device.laptop} {

    }
`;

const ScBox = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 4px auto;
    flex-grow: 1;
    justify-content: center;

    ${'' /* @media ${device.tablet} {

    } */}

`;

const BoxContent = styled(Box)`
    display: flex;
    align-items: flex-start;
    ${'' /* flex-direction: column; */}
    flex-grow: 1;
    height: 100%;
    padding: 8px;

    @media ${device.mobileM} {
        flex-direction: row;
    }
`;

const OverlayBox = styled(Box)`
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 99;
`;

const TextBox = styled(Box)`
    color: white !important;
`;

const Sc_CardContent = styled(CardContent)`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: 0px 8px;
    padding: 0px !important;
`;

export default function CardButtonWithCounter (props){

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    const [currValue, setCurrValue] = useState(0);

    function handleClick() {
        handleChange('plus')
    }

    function handleChange(action){

        let selectedAction = action;

        let recordMemory = props.record;

        let valueMemory = currValue;
        
        if(selectedAction === 'minus'){
            if(valueMemory > 1) {
                valueMemory = valueMemory - 1;
                props.onChange('minus', recordMemory);
            } else if(valueMemory === 1){
                valueMemory = 0;
                props.onChange('minus', recordMemory);
            } else {

            }
            setCurrValue(valueMemory);
        } else if (selectedAction === 'plus') {
            if( Number(props.limit) >= 1) {
                valueMemory = valueMemory + 1;
                setCurrValue(valueMemory);
                props.onChange('plus', recordMemory);
            } else {
                let alertMessage = 'exceed limit';
                props.onAlert(alertMessage);
            } 
        }
    }

    const handleAlert = React.useCallback((inputAlert) => {
        props.onAlert(inputAlert)
    })

    function renderCardBody(){
        return (
            <BoxContent
                sx={{ flexDirection: 'row', width: '100%', height: '100%' }}
            >
                {
                    props.record.fields.image &&
                    <CardMedia
                    component="img"
                    sx={{ maxWidth: sm ? 60 : 100 , height: sm ? 60 : 100, flexGrow: 1, objectFit: 'cover', borderRadius: '10px' }}
                    image={props.image}
                    alt={props.title}
                    />
                }
                <Sc_CardContent
                    sx={{ flexGrow: 1 }}
                >
                    <Typography gutterBottom variant="h5" component="div">
                        {props.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.text}
                    </Typography>
                </Sc_CardContent>
            </BoxContent>
        )
    }

    

    React.useEffect(() => {

    },[currValue])

    return(

        <ScCard 
            style={ {boxShadow: currValue > 0 ? 'unset' : '0px 4px 4px rgba(0, 0, 0, 0.25)', border: currValue > 0 ? '3px solid #2185D5' : 'none', position: 'relative', backgroundColor: '#FAFAFA'} }
        >
            <ScBox
                sx={{ alignItems: 'flex-start', display: 'flex', position: 'relative', width: '100%', height: '100%' }}
            >
                <CardActionArea
                    sx={{ width: '100%', height: '100%' }}
                    onClick={() => handleClick()}
                    disabled={currValue === 0 ? false : true}
                >
                    {renderCardBody()}
                </CardActionArea>
                {   props.disabled &&
                    <OverlayBox>
                        <TextBox>
                            <Typography variant="body1" color="text.secondary" sx={{ color: 'white' }}>
                                {
                                    props.disabledMessage
                                }
                            </Typography>
                        </TextBox>
                    </OverlayBox>
                }
                {   currValue > 0 &&
                    <Box sx={{ minHeight: '100%', display: 'flex', alignItems: 'center', position: 'absolute', right: 0 }}>
                        <NumberDialBox
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <NumberDial record={props.record} limit={props.limit} initialValue={currValue} onChange={(action) => handleChange(action)} onAlert={(alertMessage) => handleAlert(alertMessage)} />
                        </NumberDialBox>
                    </Box>
                }
            </ScBox>
        </ScCard>

    )

}