import * as React from "react";
import styled, { keyframes } from 'styled-components';
import ProductItem from "./ProductItem";
import Box from "@mui/material/Box";
import Loading from "./Loading";
import Grid from "@mui/material/Grid";
// import { styled } from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import Button from "@mui/material/Button";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Grow from '@mui/material/Grow';
import {useHistory} from 'react-router-dom';
import { device } from './device';


const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledImg = styled.img`
    width: ${props => props.expanded ? "80px" : "120px"};
    height: ${props => props.expanded ? "80px" : "120px"};
    animation-name: ${props => props.expanded ? scaleDown : scaleUp};
    animation-duration: 0.3s;
`;

const Sc_AccordionSummary = styled(AccordionSummary)`
    flex-direction: column;
    @media ${device.mobileM}{
        flex-direction: row;
    }
`;

const ContentBox = styled(Box)`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    min-width: 100%;
    @media ${device.mobileS}{
        flex-direction: row;
        justify-content: start;
    }
`;

const TitleBox = styled(Typography)`
    text-align: center;
    @media ${device.mobileS}{
        text-align: start;
    }
`;

const CollapseButton = styled(Button)`
    padding: 16px;
`;

const scaleDown = keyframes`
    from {
            width: 120px;
            height: 120px;
        }
    to {
        width: 80px;
        height: 80px;
    }
`

const scaleUp = keyframes`
    from {
            width: 80px;
            height: 80px;
        }
    to {
        width: 120px;
        height: 120px;
    }
`

function ProductSectionAccordion(props) {

    const history = useHistory();

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.up("xs"));
    const sm = useMediaQuery(theme.breakpoints.up("sm"));
    const md = useMediaQuery(theme.breakpoints.up("md"));
    const lg = useMediaQuery(theme.breakpoints.up("lg"));
    const xl = useMediaQuery(theme.breakpoints.up("xl"));

    const myRef = React.useRef(null);

    const executeScroll = () => myRef.current.scrollIntoView(
        {
            behavior: 'smooth',
            block: 'start',
        }
    );

    const [category, setCategory] = React.useState(props.categoryRecord)

    const [storeContent, setStoreContent] = React.useState(props.storeContent);

    const [products, setProducts] = React.useState(props.productContent);

    const [loaded, setLoaded] = React.useState(false);

    //handle accordion
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = () => (event, isExpanded) => {
        setExpanded(isExpanded ? true : false);
        executeScroll();
    };

    async function loadData() {
        // title = props.title;
        // await setStoreContent(props.storeContent);
        // await loadTitle();
        await loadProductInSection();
        setLoaded(true);
    }

    function handleCashierCallItem(inputId) {
        props.onCashierCallItem(inputId);
    }

    async function loadProductInSection() {
        setProducts((prevValue) => {
            return prevValue.filter((item) => {
                if(props.cashier){
                    if(item.fields["product-active-cashier"]){
                        return item.fields["category-index"].includes(category.id);
                    }
                } else {
                    if(item.fields["product-active-client"]){
                        return item.fields["category-index"].includes(category.id);
                    }
                }
                return;
            });
        });
    }

    // const handleProductClick = React.useCallback((record, action) => { 

    //     {   props.cashier ?
    //         history.push(`/cashier/${props.store}/${props.language}/${record.id}`) :
    //         history.push({
    //             pathname: `/product/${props.store}/${props.language}/${record.id}`,
    //             data: {
    //                 item: record
    //             }
    //         })
    //     }
    
    // },[history]); 

    function renderItem() {
        return (
            <Grid container spacing={1}>
                {products && props.rootContent &&
                    products?.map((el, index) => {
                        return loaded ? (
                            <Grid
                                key={index}
                                item
                                xs={xl ? 3 : md ? 4 : sm ? 6 : 12}
                                style={{ display: "flex" }}
                            >
                                <ProductItem
                                    cashier={props.cashier ? true : false}
                                    simpleLayout={props.simpleLayout}
                                    key={index}
                                    language={props.language}
                                    store={props.store}
                                    item={el}
                                    rootContent={props.rootContent}
                                    onCashierCallItem={(inputId) => handleCashierCallItem(inputId)}
                                />
                                {/* <SimpleCardButton
                                    data={el}
                                    warning={props.rootContent['sold-out'][`${props.language}`]}
                                    active={el.fields['sold-out'] ? false : true}
                                    title={el.fields[`${props.language}`]}
                                    text={`${el.fields[`cups-${props.language}`]} €`}
                                    image={el.fields.images && el.fields.images[0].thumbnails.large.url}
                                    onClick={(record, action) => handleProductClick(record, action)}
                                /> */}
                            </Grid>
                        ) : (
                            <Loading key={index} />
                        );
                    })}
            </Grid>
        );
    }

    function renderTitle() {
        return (
            <>
                {   category &&
                    storeContent &&
                    props.categoryRecord &&
                    loaded
                    ? category.fields[`${props.language}`]
                    : "Category"
                }
            </>
        );
    }
    React.useEffect(() => {
        let mounted = true;
        // if(!loaded && mounted) {

        //     setTimeout(() => {
        //         loadData();
        //     }, 1000);

        // } else {
        //     loadData();

        // }
        category && loadData();
        return () => {
            mounted = false;
        };
    }, []);

    return (
        <Box ref={myRef}>
            <Accordion
                expanded={expanded}
                onChange={handleChange()}
                // color={expanded && 'blue_lighten_4'}
                sx={{ 
                    "backgroundColor": expanded ? 'rgba(187, 222, 251, 1)' : '#FAFAFA', 
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', 
                    borderRadius: '10px' 
                }}
            >
                <Sc_AccordionSummary
                    // color='grey_lighten_5'
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <ContentBox>
                        {   category && category.fields['image'] && !props.simpleLayout &&
                            <StyledImg
                                expanded={expanded}
                                sx={{ "objectFit": "contain", flexShrink: 0 }}
                                src={`${category.fields['image'][0].thumbnails.small.url}`}
                                srcSet={`${category.fields['image'][0].thumbnails.large.url}`}
                                alt='category image'
                                loading='lazy'
                            />
                        }
                        <TitleBox variant="h6" sx={{ mt: "auto", mb: 'auto', maxWidth: '100%' }}>
                            {loaded && category && renderTitle()}
                        </TitleBox>
                    </ContentBox>
                </Sc_AccordionSummary>
                <Grow
                    in={expanded}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(expanded ? { timeout: 500 } : {})}
                >
                    <AccordionDetails>
                        <Container>{renderItem()}</Container>
                        <CollapseButton sx={{ width: '100%' }} onClick={handleChange() }><ExpandLessIcon /></CollapseButton>
                    </AccordionDetails>
                </Grow>
            </Accordion>
        </Box>
    );
}

export default ProductSectionAccordion;
