import React, { useEffect } from "react";
import styled from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const H6 = styled.h6`
    margin: 0;
`;

const P = styled.p`
    margin: 0;
`;

const Container = styled.div`
    max-width: 1440px;
`;

function StoreInfo(props) {
    return (
        <Container>
            <H6>
                {props.rootContent["restaurant-detail"] &&
                    props.rootContent["restaurant-detail"][`${props.language}`]}
            </H6>
            <TableContainer component={Paper} sx={{ maxWidth: 650 }}>
                <Table size="small" aria-label="a dense table">
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {props.rootContent["restaurant-address"] &&
                                    props.rootContent["restaurant-address"][
                                        `${props.language}`
                                    ]}
                            </TableCell>
                            <TableCell align="left">
                                {props.storeContent["restaurant-address"] &&
                                    props.storeContent["restaurant-address"][
                                        `${props.language}`
                                    ]}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {props.rootContent["restaurant-telephone"] &&
                                    props.rootContent["restaurant-telephone"][
                                        `${props.language}`
                                    ]}
                            </TableCell>
                            <TableCell align="left">
                                {props.storeContent["restaurant-telephone"] &&
                                    props.storeContent["restaurant-telephone"][
                                        `${props.language}`
                                    ]}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {props.rootContent["time-required"] &&
                                    props.rootContent["time-required"][
                                        `${props.language}`
                                    ]}
                            </TableCell>
                            <TableCell align="left">
                                {props.storeContent["time-required"] &&
                                    props.storeContent["time-required"][
                                        `${props.language}`
                                    ]}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <H6>
                {props.rootContent["open-hours"] &&
                    props.rootContent["open-hours"][`${props.language}`]}
            </H6>
            <TableContainer component={Paper} sx={{ maxWidth: 650 }}>
                <Table size="small" aria-label="a dense table">
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {props.rootContent["monday"] &&
                                    props.rootContent["monday"][
                                        `${props.language}`
                                    ]}
                            </TableCell>
                            <TableCell align="left">
                                {   props.settings["open-day-1"] &&
                                    props.settings["open-day-1"].time / 3600
                                }
                                -
                                {   props.settings["close-day-1"] &&
                                    props.settings["close-day-1"].time / 3600
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {props.rootContent["tuesday"] &&
                                    props.rootContent["tuesday"][
                                        `${props.language}`
                                    ]}
                            </TableCell>
                            <TableCell align="left">
                                {   props.settings["open-day-2"] &&
                                    props.settings["open-day-2"].time / 3600
                                }
                                -
                                {   props.settings["close-day-2"] &&
                                    props.settings["close-day-2"].time / 3600
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {props.rootContent["wednesday"] &&
                                    props.rootContent["wednesday"][
                                        `${props.language}`
                                    ]}
                            </TableCell>
                            <TableCell align="left">
                                {   props.settings["open-day-3"] &&
                                    props.settings["open-day-3"].time / 3600
                                }
                                -
                                {   props.settings["close-day-3"] &&
                                    props.settings["close-day-3"].time / 3600
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {props.rootContent["thursday"] &&
                                    props.rootContent["thursday"][
                                        `${props.language}`
                                    ]}
                            </TableCell>
                            <TableCell align="left">
                                {   props.settings["open-day-4"] &&
                                    props.settings["open-day-4"].time / 3600
                                }
                                -
                                {   props.settings["close-day-4"] &&
                                    props.settings["close-day-4"].time / 3600
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {props.rootContent["friday"] &&
                                    props.rootContent["friday"][
                                        `${props.language}`
                                    ]}
                            </TableCell>
                            <TableCell align="left">
                                {   props.settings["open-day-5"] &&
                                    props.settings["open-day-5"].time / 3600
                                }
                                -
                                {   props.settings["close-day-5"] &&
                                    props.settings["close-day-5"].time / 3600
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {props.rootContent["saturday"] &&
                                    props.rootContent["saturday"][
                                        `${props.language}`
                                    ]}
                            </TableCell>
                            <TableCell align="left">
                                {   props.settings["open-day-6"] &&
                                    props.settings["open-day-6"].time / 3600
                                }
                                -
                                {   props.settings["close-day-6"] &&
                                    props.settings["close-day-6"].time / 3600
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {props.rootContent["sunday"] &&
                                    props.rootContent["sunday"][
                                        `${props.language}`
                                    ]}
                            </TableCell>
                            <TableCell align="left">
                                {   props.settings["open-day-0"] &&
                                    props.settings["open-day-0"].time / 3600
                                }
                                -
                                {   props.settings["close-day-0"] &&
                                    props.settings["close-day-0"].time / 3600
                                }
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}

export default StoreInfo;
