import * as React from "react";
import styled from "styled-components";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const H6 = styled.h6`
    margin: 0;
`;

const Container = styled.div`
    max-width: 1440px;
`;

const Div = styled.div``;

const Ol = styled.ol`
    margin: 0;
    padding: 0;
`;

const Li = styled.li`
    margin: 0;
    padding: 0;
`;

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

let reminders = [];

function MoreInfo(props) {
    reminders = [];

    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    function readReminders() {
        for (let i = 0; i < Object.keys(props.storeContent).length; i++) {
            if (props.storeContent[`restaurant-reminder-${i + 1}`]) {
                reminders.push(
                    props.storeContent[`restaurant-reminder-${i + 1}`]
                );
                // return (
                //     <>
                //         {
                //             props.storeContent[`restaurant-reminder-${i + 1}`] &&
                //             <ListItem>
                //                 <Typography>{i + 1}. </Typography>
                //                 <ListItemText
                //                     primary={
                //                         props.storeContent[`restaurant-reminder-${i + 1}`] && props.storeContent[`restaurant-reminder-${i + 1}`][`${props.language}`]
                //                     }
                //                     // secondary={secondary ? 'Secondary text' : null}
                //                 />
                //                 hello
                //             </ListItem>
                //         }
                //     </>
                // )
            }
        }
    }

    React.useEffect(() => {
        readReminders();
    }, []);

    return (
        <Container>
            {/* <Div>
                <H6>
                {
                    props.rootContent['benefit-description'] && props.rootContent['benefit-description'][`${props.language}`]
                }
                </H6>
            </Div>
            <Div>
                <Stack spacing={1} alignItems="left">
                    <Stack direction="row" spacing={1}>
                        <Chip
                            label={
                                props.storeContent['benefit-1-title'] && props.storeContent['benefit-1-title'][`${props.language}`]
                            }
                            color="primary"
                            onClick={() => handleClickOpen()}
                        />
                    </Stack>
                </Stack>
                <Dialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {
                            props.storeContent['benefit-1-title'] && props.storeContent['benefit-1-title'][`${props.language}`]
                        }
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            {
                                props.storeContent['benefit-1-content'] && props.storeContent['benefit-1-content'][`${props.language}`]
                            }
                        </Typography>
                    </DialogContent>
                </Dialog>
            </Div> */}
            {/* <Div>
                <H6>
                    {props.rootContent["restaurant-reminder"] &&
                        props.rootContent["restaurant-reminder"][
                            `${props.language}`
                        ]}
                </H6>
            </Div> */}
            <Div>
                <List dense={dense}>
                    {/* {console.log(Object.keys(props.storeContent).length)} */}
                    {props.storeContent && readReminders()}

                    {/* return (
                            <>
                                {
                                    props.storeContent[`restaurant-reminder-${count}`] && 
                                    <ListItem>
                                        <Typography>{count}. </Typography>
                                        <ListItemText
                                            primary={
                                                props.storeContent[`restaurant-reminder-${count}`] && props.storeContent[`restaurant-reminder-${count}`][`${props.language}`]
                                            }
                                            // secondary={secondary ? 'Secondary text' : null}
                                        />
                                    </ListItem>
                                }
                            </>
                        ) */}

                    <ListItem>
                        <Typography>1. </Typography>
                        <ListItemText
                            primary={
                                props.storeContent["restaurant-reminder-1"] &&
                                props.storeContent["restaurant-reminder-1"][
                                    `${props.language}`
                                ]
                            }
                            // secondary={secondary ? 'Secondary text' : null}
                        />
                    </ListItem>
                    <ListItem>
                        <Typography>2. </Typography>
                        <ListItemText
                            primary={
                                props.storeContent["restaurant-reminder-2"] &&
                                props.storeContent["restaurant-reminder-2"][
                                    `${props.language}`
                                ]
                            }
                        />
                    </ListItem>
                </List>
            </Div>
        </Container>
    );
}

export default MoreInfo;
