import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";


const Div = styled(Box)`
    display: flex;
`;

const ButtonsBox = styled(ButtonGroup)`
    min-width: 100% !important;
`;

const ScGrid = styled(Grid)`
    min-width: 100%;
    width: 100%;
`;


export default function SimpleButtonsGroup ({ content, language, product, helperText, buttons, onClick }){

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.up("xs"));
    const sm = useMediaQuery(theme.breakpoints.up("sm"));
    const md = useMediaQuery(theme.breakpoints.up("md"));
    const lg = useMediaQuery(theme.breakpoints.up("lg"));
    const xl = useMediaQuery(theme.breakpoints.up("xl"));

    const [helperInfo, setHelperInfo] = useState(helperText);

    const [currValue, setCurrValue] = useState();

    const updateValue = async (e) => {
        const {name, value} = e.target;
        setCurrValue(value);
        setHelperInfo('');
        value && onClick(value);
    };

    return(

        <Box
            sx={{
                display: 'flex',
                minWidth: '100%',
                '& > *': {
                m: 1,
                },
            }}
        >
            <ButtonsBox
                variant="outlined" 
                aria-label="outlined button group"
            >
                <ScGrid 
                    container 
                    spacing={2}
                >
                    {   
                        buttons?.map((el, index) => {
                            return (
                                <Grid
                                    key={index}
                                    xs={xl ? 1 : md ? 1 : sm ? 3 : xs ? 4 : 12}
                                    style={{ display: 'flex', justifyContent: 'center' }}
                                >
                                    <Button 
                                        sx={{ 
                                            boxShadow: `${Number(currValue) === el.value ? 'unset' : '0px 4px 4px rgba(0, 0, 0, 0.25' }`,
                                        }} 
                                        color={`${Number(currValue) === el.value ? "primary" : "white"}`} 
                                        variant='contained' 
                                        name={el.name} 
                                        value={el.value} 
                                        onClick={(e) => updateValue(e)}
                                    >
                                        {el.label}
                                    </Button>
                                </Grid>
                            )
                        })
                    }
                </ScGrid>
            </ButtonsBox>
        </Box>

    )

}