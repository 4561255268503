import React from 'react';
import { Stack, Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import Chip from '@mui/material/Chip';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const NumberDisplay = styled(Chip)`
  box-sizing: border-box;
  border-radius: 5px !important;
  border: 1px solid #ccc;
  color: #ccc;
  padding: 0 24px;
  ${props => props.active && ({
     border: '1px solid #2185D5',
     color: '#2185D5'
  })}
`;

function ProductDial(props) {

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.up('xs'));
    const sm = useMediaQuery(theme.breakpoints.up('sm'));

    // const [value, setValue] = React.useState(props.currValue);

    function handleMinus(){
        // setValue( (prevValue)=> {
        //     return prevValue - 1;
        // } )
        props.onChange('minus', props.item);
    }
    
    function handlePlus(){
        // setValue( (prevValue)=> {
        //     return prevValue + 1;
        // } )
        props.onChange('plus', props.item);
    }



    return (
        <Stack ml={ xs && 2} spacing={1} direction={ "row" }>
            <Button variant="contained" onClick={() => handleMinus()}>
                <FontAwesomeIcon icon={faMinus} />
            </Button>
            <NumberDisplay label={props.item['quantity']} color={ sm ? "primary" : xs ? 'secondary' : 'secondary'} variant={ sm ? "outlined" : xs ? 'filled' : 'filled'}></NumberDisplay>
            <Button variant="contained" onClick={() => handlePlus()}>
                <FontAwesomeIcon icon={faPlus} />
            </Button>
        </Stack>
    )
}

export default ProductDial;